import "../../assets/scss/solution.scss";

const Solutions = () => {
  return (
    <section id="solutions" className="our-solution">
      <div className="title-section">GIẢI PHÁP ROBOT CHO ĐA NGÀNH</div>
      <div className="horizontal-line"></div>
      <div className="row">
        <div className="item col-lg-3">
          <div className="item-img">
            <img src={require("../../assets/images/sx.webp")} alt="" />
          </div>
          <div className="item-title">SẢN XUẤT</div>
        </div>
        <div className="item col-lg-3">
          <div className="item-img">
            <img src={require("../../assets/images/yt.webp")} alt="" />
          </div>
          <div className="item-title">Y TẾ</div>
        </div>
        <div className="item col-lg-3">
          <div className="item-img">
            <img src={require("../../assets/images/cskh.webp")} alt="" />
          </div>
          <div className="item-title">CHĂM SÓC KHÁCH HÀNG</div>
        </div>
        <div className="item col-lg-3">
          <div className="item-img">
            <img src={require("../../assets/images/gd.webp")} alt="" />
          </div>
          <div className="item-title">GIÁO DỤC</div>
        </div>
      </div>
      <div className="row blue-bg"></div>
    </section>
  );
};

export default Solutions;
