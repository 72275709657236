import "../../assets/scss/footer.scss";

const footer = () => {
  return (
    <footer className="footer">
        <span>
          <a href="https://www.facebook.com/TMAInnovation">
            <i className="fa fa-facebook-f"></i>
          </a>
        </span>
        <span>
          <a href="https://www.linkedin.com/in/tma-innovation">
            <i className="fa fa-linkedin"></i>
          </a>
        </span>
        <span>
          <a href="mailto:contact@tmainnovation.vn">
            <i className="fa fa-envelope"></i>
          </a>
        </span>
    </footer>
  );
};
export default footer;
