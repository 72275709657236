import "../../assets/scss/typicalSolutions.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import useIntersectionObserver from "@react-hook/intersection-observer";
import { useRef } from "react";

const TypicalSolutions = () => {
  const containerRef = useRef();
  const lockRef = useRef(false);
  const { isIntersecting } = useIntersectionObserver(containerRef);
  if (isIntersecting) {
    lockRef.current = true;
  }
  return (
    <section id="typical-solutions" className="typical-solutions">
      <div className="title-section">GIẢI PHÁP TIÊU BIỂU</div>
      <div className="horizontal-line"></div>

      <div className="border border-right">
        <div className="sub-robot container">
          <p className="sub-title">GIẢI PHÁP SẢN XUẤT ROBOT</p>
          <span className="sub-desciption">
            Robot hỗ trợ kéo, chở, nâng hàng tự động trong các kho bãi, nhà máy
            sản xuất, công trình, v.v. Hỗ trợ di chuyển và sắp xếp hàng hóa
            nặng, nguy hiểm; giúp các doanh nghiệp gia tăng năng suất và tiết
            kiệm chi phí nhân lực.
          </span>
          <div className="row">
            <div className="col-lg-6">
              <ul>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span> Công nghệ tự động điều hướng SLAM và Lidar </span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>
                    {" "}
                    Tự động tránh né vật cản, tìm đường đi qua hoặc cảnh báo
                  </span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Cho phép điều khiển từ xa thông qua ứng dụng.</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>
                    Linh hoạt tùy biến kiểu dáng, kích thước, tải trọng theo yêu
                    cầu
                  </span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Made by TMA & Indruino</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span> Made in Vietnam</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-6" ref={containerRef}>
              {lockRef.current && (
                <iframe
                  width="484"
                  height="278"
                  src="https://www.youtube.com/embed/t1Ph4HQHiCk"
                  title="GIẢI PHÁP ROBOT
                  SẢN XUẤT"
                  frameborder="0"
                  allow="accelerometer; autoplay;
                   clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                  loading="lazy"
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="border border-left">
        <div className="sub-robot container">
          <p className="sub-title">GIẢI PHÁP Y TẾ</p>
          <span className="sub-desciption">
            Ứng dụng AI và tự động hoá, được trang bị nhiều chức năng thông minh
            với nhiệm vụ hỗ trợ nhân viên y tế thực hiện các công việc chăm sóc
            bệnh nhân.
          </span>
          <div className="row d-flex">
            <div className="col-lg-6 order">
              <ul>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Giám sát chỉ số sức khỏe của bệnh nhân</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>
                    Hiển thị thông tin bệnh án của bệnh nhân cho bác sĩ
                  </span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Dẫn đường, nhắc nhở bệnh nhân uống thuốc</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>
                    Hỗ trợ kết nối bệnh nhân với nhân viên y tế/ người nhà
                  </span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Phát tín hiệu cảnh báo trong trường hợp khẩn</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Hỗ trợ theo dõi từ xa</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-6" ref={containerRef}>
              {lockRef.current && (
                <iframe
                  width="484"
                  height="278"
                  src="https://www.youtube.com/embed/5R3m5rxeKiw"
                  title="GIẢI PHÁP ROBOT Y TẾ"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write;
                  encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                  loading="lazy"

                ></iframe>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="border border-right">
        <div className="sub-robot container">
          <p className="sub-title">Giải pháp Robot Chăm sóc Khách hàng</p>
          <span className="sub-desciption">
            Giúp tăng hiệu quả, nâng cao sự hài lòng của khách hàng, đồng thời
            tạo sự khác biệt hóa và tiết kiệm chi phí nhân công cho doanh
            nghiệp.
          </span>
          <div className="row">
            <div className="col-lg-7">
              <ul>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Chào hỏi và đón tiếp khách hàng</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Tư vấn sản phẩm/ dịch vụ bằng giọng nói/ văn bản</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span> Dẫn dắt tham quan và chỉ đường</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>
                    Thu thập dữ liệu khách hàng trong quá trình tương tác
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-lg-5" ref={containerRef}>
              {lockRef.current && (
                <iframe
                  width="484"
                  height="278"
                  src="https://www.youtube.com/embed/EHVzCHc5G-E"
                  title="GIẢI PHÁP ROBOT
                                CSKH"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write;
                                encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                  loading="lazy"
                  
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="border border-left">
        <div className="sub-robot container height">
          <p className="sub-title">Giải pháp Robot Giáo dục</p>
          <span className="sub-desciption">
            Hỗ trợ giáo viên/ gia đình trong việc giảng dạy, khuyến khích học
            sinh tìm hiểu kiến thức chuyên sâu, đảm bảo được sự cân bằng giữa
            học tập và giải trí.
          </span>
          <div className="row d-flex">
            <div className="col-lg-6 order">
              <ul>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Giải toán, đố vui, dịch thuật</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span> Nhận diện, chia sẻ cảm xúc người dùng</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Nhắc nhở lịch trình, thời gian biểu</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Tích hợp tính năng giải trí</span>
                </li>
                <li>
                  <img
                    src={require("../../assets/images/check.webp")}
                    alt=""
                    className="check"
                  />
                  <span>Tạo động lực học tập chuyên sâu.</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img
                    src={require("../../assets/images/alphamini1.webp")}
                    alt="alphamini1"
                    className="img-slider"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={require("../../assets/images/alphamini2.webp")}
                    alt="alphamini2"
                    className="img-slider"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={require("../../assets/images/alphamini3.webp")}
                    alt="alphamini2"
                    className="img-slider"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TypicalSolutions;
