import "./App.css";
import Layout from "./components/layout/layout";
import Home from "./components/home/home";
import Solutions from "./components/solutions/solutions";
import TypicalSolutions from "./components/typicalSolutions/typicalSolutions";
import AboutUs from "./components/aboutUs/aboutUs";
import ReasonAndSlider from "./components/reasonAndSlider/index";
import Contact from "./components/contact/contact";
import { useEffect } from "react";
import LazyLoad from "react-lazyload";

function App() {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <Layout>
      <div id="home">
        <LazyLoad height={500} once>
          <Home />
        </LazyLoad>
      </div>
      <div id="solutions">
        <LazyLoad height={500} once>
          <Solutions />
        </LazyLoad>
      </div>
      <div id="typical-solutions">
        <LazyLoad height={500} once>
          <TypicalSolutions />
        </LazyLoad>
      </div>
      <div id="about-us">
        <LazyLoad height={500} once>
          <AboutUs />
        </LazyLoad>
      </div>
      <div id="">
        <LazyLoad height={500} once>
          <ReasonAndSlider />
        </LazyLoad>
      </div>
      <div id="contact">
        <LazyLoad height={500} once>
          <Contact />
        </LazyLoad>
      </div>
    </Layout>
  );
}

export default App;
