import "../../assets/scss/home.scss"

const Home = () => {
  return (
    <section id="home">
      <div className="cls-banner-content" id="cls-banner-content">
        <div className="row">
          <div className="col-lg-12">
            <p>TMA SMART ROBOT</p>
            <span>
              Tối ưu hóa chi phí, giải quyết thách <br /> thức về lao động và tự
              động hóa <br /> lao động
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
