import React, { useEffect } from "react";
import { Link } from "react-scroll";
import "../../assets/scss/header.scss";
import $ from "jquery";

const Header = (props) => {
  const handleToggleBar = () => {
    props.setShowNav(!props.showNav);
  };

  const navigateHandle = () => {
    props.setShowNav(false);
  };

  const menu = [
    {
      id: 1,

      label: "TRANG CHỦ",
      data_id: "home",
      classActive: "active"
    },

    {
      id: 2,

      label: "GIẢI PHÁP",
      data_id: "solutions",
    },
    {
      id: 3,

      label: "SẢN PHẨM",
      data_id: "typical-solutions",
    },
    {
      id: 4,

      label: "VỀ CHÚNG TÔI",
      data_id: "about-us",
    },
    {
      id: 5,

      label: "LIÊN HỆ",
      data_id: "contact",
    },
  ];

  $(window).on("load", function () {
    "use strict";
    $("#navbar li a").on("click", function (event) {
      $("#navbar li a").each(function () {
        if ($(this).hasClass("active")) {
          $(this).removeClass("active");
        }
      });
      $(this).addClass("active");
      $("div.rectangle")
        .stop()
        .animate({
          left: $(this).offset().left + 34,
        });
    });
  });

  return (
    <header className="header" id="header">
      <nav className="nav-bar" id="navbar">
        <div className="container nav">
          <Link
            to="home"
            activeClass="active"
            smooth={true}
            duration={1500}
            className="logo"
          >
            <img src={require("../../assets/images/logo.webp")} width="120" />
          </Link>
          <div
            className={`${props.showNav ? "nav_show" : ""} ${"nav_mobile"} ${
              props.showNav ? "nav_mobile_active" : ""
            } ${"nav_content"}`}
          >
            <span
              className={`${"title_nav_mobile"} ${
                props.showNav ? "title_nav_mobile_active" : ""
              }`}
            >
              Menu
            </span>
            <div className="rectangle"></div>
            <ul>
              {menu.map((item) => {
                return (
                  <li key={item.id} className={"nav_item"}>
                    <Link
                      onClick={navigateHandle}
                      smooth={true}
                      spyThrottle={500}
                      offset={0}
                      duration={1500}
                      to={item.data_id}
                      className={`new_tag_a ${item?.classActive}`}
                    >
                      {item.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>

      <input
        className={"menu_check"}
        type="checkbox"
        onClick={handleToggleBar}
        name=""
        id=""
      />
      <div className={"burger"}>
        <span
          className={`${"line"} ${"line1"} ${
            props.showNav ? `${"line1_transform"} ${"line_transform"}` : ""
          }`}
        ></span>
        <span
          className={`${"line"} ${"line2"} ${
            props.showNav ? `${"line2_transform"}` : ""
          }`}
        ></span>
        <span
          className={`${"line"} ${"line3"} ${
            props.showNav ? `${"line3_transform"} ${"line_transform"}` : ""
          }`}
        ></span>
      </div>
    </header>
  );
};

export default Header;
