import "../../assets/scss/aboutUs.scss";

const AboutUs = () => {
  return (
    <section id="about-us" className=" about-us">
      <div className="title-section">Về chúng tôi</div>
      <div className="horizontal-line"></div>
      <div className="bg-about-us">
        <div className="row">
          <div className="col-lg-3">
            <img  src={require("../../assets/images/logo-aboutUs.webp")} alt="" />
          </div>
          <div className="col-lg-9">
            <span className="material-icons rotate">format_quote</span>
            <p className="content">
              Phát huy kinh nghiệm từ 24 năm nghiên cứu với hơn 2,700 kỹ sư tài
              năng, TMA cung cấp hàng ngàn giải pháp cho nhiều tập đoàn quốc tế,
              đồng thời nhận được sự tin cậy và đánh giá cao từ phía khách hàng.
            </p>
            <p className="content">
              Với sứ mệnh phổ dụng công nghệ 4.0, TMA Innovation tập trung chú
              trọng vào nghiên cứu và phát triển các giải pháp công nghệ, đặc
              biệt là robot thông minh, giúp tự động hóa các ngành nghề, đa lĩnh
              vực.
            </p>
            <span className="material-icons float-right">format_quote</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
