import Reason from "./reason";
import SliderImages from "./sliderImages";
const Index = () => {
  return (
    <>
      <Reason />
      <SliderImages/>
    </>
  );
};

export default Index;
