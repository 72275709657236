import "../../assets/scss/reasonAndSilder/reason.scss";

const Reason = () => {
  return (
    <section id="reason" className="reason">
      <div className="title-section">Lý do chọn chúng tôi</div>
      <div className="horizontal-line"></div>
      <div className="row">
        <div className="col-lg-3">
          <img src={require("../../assets/images/solution.webp")} alt="" />
          <p>Đội Ngũ tài Năng</p>
          <span>
            2700 kỹ sư <br />
            35 trường đại học <br />
            100 thực tập sinh mỗi năm
          </span>
        </div>
        <div className="col-lg-3">
          <img src={require("../../assets/images/solution2.webp")} alt="" />
          <p>Bề Dày Kinh Nghiệm</p>
          <span>
            24 năm thành lập và
            <br />
            phát triển
          </span>
        </div>
        <div className="col-lg-3">
          <img src={require("../../assets/images/solution3.webp")} alt="" />
          <p>Đối Tác Đáng Tin Cậy</p>
          <span>
            27 quốc gia lớn <br />
            100 + Khách hàng
          </span>
        </div>
        <div className="col-lg-3">
          <img src={require("../../assets/images/solution4.webp")} alt="" />
          <p>Thành Tựu Nổi bật</p>
          <span>
            Top 10 công ty công nghệ <br />
            AI-IoT Việt Nam <br />
            30 + dự án sáng tạo
          </span>
        </div>
      </div>
    </section>
  );
};

export default Reason;
