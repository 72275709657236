import Header from "./header";
import Footer from "./footer";
import { useState } from "react";

const Layout = (props) => {
  const [showNav, setShowNav] = useState(false);
  return (
    <>
      <Header showNav={showNav} setShowNav={setShowNav} />
            <main>{props.children}</main>
      <Footer /> 
    </>
  );
};
export default Layout;
