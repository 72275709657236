import "../../assets/scss/reasonAndSilder/sliderImages.scss";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation,Autoplay } from "swiper";

import hinh1 from "../../assets/images/hinh1.webp";
import hinh2 from "../../assets/images/hinh2.webp";
import hinh3 from "../../assets/images/hinh3.webp";
import hinh4 from "../../assets/images/hinh4.webp";
import hinh5 from "../../assets/images/hinh5.webp";
import hinh6 from "../../assets/images/hinh6.webp";
import hinh7 from "../../assets/images/hinh7.webp";
import { useRef } from "react";

const SliderImages = () => {
  const Images = [hinh1, hinh2, hinh3, hinh4, hinh5, hinh6, hinh7];

  const swiperRef = useRef();

  return (
    <section id="screenshots" className="screenshots">
      <div className="title-section">Hình ảnh</div>
      <div className="horizontal-line"></div>
      <div className="images">
        <span className="button px-3 iconleft3" onClick={() => swiperRef.current?.slidePrev()}><i className="glyphicon glyphicon-menu-left "></i></span>
        <Swiper
          spaceBetween={40}
          slidesPerView={3}
          navigation={false}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Autoplay, Pagination, Navigation]}
          loop={true}
          centeredSlides={true}
          className="mySwiper"
        >
          {Images &&
            Images.map((item, inx) => {
              return (
                <SwiperSlide key={inx}>
                  <img src={item} alt={item} className="img-slider" />
                </SwiperSlide>
              );
            })}
        </Swiper>
        <span className="button px-3 iconright3" onClick={() => swiperRef.current?.slideNext()}><i className="glyphicon glyphicon-menu-right "></i></span>
      </div>
    </section>
  );
};

export default SliderImages;
