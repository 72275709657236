import "../../assets/scss/contact.scss";

const Contact = () => {
  return (
    <section id="contact" className="contact-us">
        <div className="title-section padding-top">Về chúng tôi</div>
        <div className="horizontal-line"></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <img src={require("../../assets/images/phone-icon.webp")}  alt="" /><br/>
                    <span className="infor">(+84) 28 3995 1062</span>
                </div>
                <div className="col-lg-6">
                    <a href="https://goo.gl/maps/86iDSzNaA21y87yH6"><img  src={require("../../assets/images/address-icon.webp")} alt="" border="0"/></a><br/>
                    <span className="infor">Tòa nhà TMA, đường số 10, Công viên phần mềm <br/> 
                        Quang Trung, 1 Tô Ký, Quận 12, Hồ Chí Minh</span>
                </div>
                <div className="col-lg-3">
                    <img src={require("../../assets/images/email-icon.webp")} alt="" /><br/>
                    <span className="infor">contact@tmainnovation.vn</span>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Contact;
